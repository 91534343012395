import React from "react";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { featureCollection } from "@turf/helpers";
import ImportExport from "./ImportExport";
import { connect, actions } from "../store";

const styles = theme => ({
  container: {
    margin: theme.spacing.unit * 2
  },
  icon: {
    marginRight: theme.spacing.unit
  },
  input: {
    marginLeft: theme.spacing.unit
  }
});

const Settings = ({ classes, points, types }) => (
  <div className={classes.container}>
    <Typography variant="h6" className={classes.title}>
      POI Types
    </Typography>
    <div className={classes.demo}>
      <List>
        {types.map(({ name, color }, i) => (
          <ListItem key={i}>
            <input
              type="color"
              value={color}
              onChange={event =>
                actions.editType(i, { color: event.target.value })
              }
            />

            <TextField
              className={classes.input}
              value={name}
              onChange={event =>
                actions.editType(i, { name: event.target.value })
              }
            />

            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Delete"
                onClick={() => actions.deleteType(i)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem>
          <Button
            edge="end"
            aria-label="Delete"
            onClick={() => actions.addType()}
            disabled={types.length > 10}
          >
            <AddIcon /> Add new POI type
          </Button>
        </ListItem>
      </List>
    </div>

    <Typography variant="h6" className={classes.title}>
      Advanced
    </Typography>
    <Grid container spacing={16}>
      <Grid item>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          disabled={_.isEqual(featureCollection([]), points)}
          onClick={() => actions.setPoints(featureCollection([]))}
        >
          <DeleteForeverIcon className={classes.icon} /> Delete All POI
        </Button>
      </Grid>
    </Grid>
    <ImportExport
      field="points"
      copy="You can use the field below to import, export, or edit the GeoJSON for your Points of Interest."
      title="Import/Export GeoJSON"
    />
    <ImportExport
      field="types"
      copy="You can use the field below to import, export, or edit the types of POI."
      title="Import/Export Types"
    />
  </div>
);

const mapStateToProps = ({ points, types }) => ({ points, types });

export default withStyles(styles)(connect(mapStateToProps)(Settings));
