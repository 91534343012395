import React from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";

class PoiMarker extends React.Component {
  refmarker = React.createRef();

  onDragend = () => {
    const marker = this.refmarker.current;
    if (marker != null) {
      const { lat, lng } = marker.leafletElement.getLatLng();
      this.props.onDragend([lat, lng]);
    }
  };

  render() {
    const { point, color } = this.props;
    const style = `background-color:${color};height:12px;width:12px;box-shadow:1px 2px 2px;border-radius:6px`;
    return (
      <Marker
        position={point}
        onClick={this.props.onClick}
        onDragend={this.onDragend}
        ref={this.refmarker}
        draggable
        opacity={0.75}
        icon={L.divIcon({
          className: "poi-icon",
          html: `<div style="${style}" />`
        })}
      />
    );
  }
}

export default PoiMarker;
