import React from "react";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { actions, connect } from "../store";

const PointDialog = ({ pointId, name = "", color = "#000000" }) => (
  <Dialog
    open={Boolean(pointId)}
    onClose={() => actions.setPointId(null)}
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle>
      <TextField
        value={name}
        onChange={event =>
          actions.editPointProperties(pointId, { name: event.target.value })
        }
      />
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        Color{" "}
        <input
          type="color"
          value={color}
          onChange={event =>
            actions.editPointProperties(pointId, { color: event.target.value })
          }
        />
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button
        onClick={() => {
          actions.setPointId(null);
          actions.deletePoi(pointId);
        }}
        color="secondary"
      >
        DELETE
      </Button>
      <Button onClick={() => actions.setPointId(null)} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);
const mapStateToProps = ({ points, pointId }) => ({
  pointId,
  ...(pointId && _.find(points.features, ({ id }) => id === pointId).properties)
});
export default connect(mapStateToProps)(PointDialog);
