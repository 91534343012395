import React from "react";
import { startCase } from "lodash";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { connect, actions } from "../store";

function importExport({ state, field, title, copy }) {
  const blob = new Blob([JSON.stringify(state[field], null, 2)], {
    type: "application/json"
  });

  const onUpload = event => {
    const reader = new FileReader();
    reader.onload = event =>
      actions[`set${startCase(field)}`](JSON.parse(event.target.result));

    reader.readAsText(event.target.files[0]);
  };

  return (
    <Grid container spacing={16}>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          href={window.URL.createObjectURL(blob)}
          download={`${field}.json`}
        >
          Export {startCase(field)}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <label>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onUpload}
            accept=".json"
          />
          <Button fullWidth variant="outlined" color="primary" component="span">
            Import {startCase(field)}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => ({ state });
export default connect(mapStateToProps)(importExport);
