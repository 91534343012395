import _ from "lodash";
import createStore from "react-waterfall";
import uuid from "uuid";
import getLocation from "./util/getLocation";
import { featureCollection, point } from "@turf/helpers";

function cloneAsObject(obj) {
  if (obj === null || !(obj instanceof Object)) {
    return obj;
  }
  var temp = obj instanceof Array ? [] : {};
  // ReSharper disable once MissingHasOwnPropertyInForeach
  for (var key in obj) {
    temp[key] = cloneAsObject(obj[key]);
  }
  return temp;
}

const config = {
  initialState: {
    drawer: false,
    path: window.location.pathname,
    view: "Map",
    location: {
      ...(JSON.parse(localStorage.getItem("location") || null) || {
        coords: { latitude: 38.9043018, longitude: -77.0237815 }
      }),
      cached: true
    },
    zoomLevel: JSON.parse(localStorage.getItem("zoomLevel") || "16"),
    error: null,
    points:
      JSON.parse(localStorage.getItem("points") || "null") ||
      featureCollection([]),
    pointId: null,
    types: JSON.parse(localStorage.getItem("types") || "null") || [
      {
        color: "#3f51b5",
        name: "Point of Interest"
      }
    ]
  },
  actionsCreators: {
    // Basic UI interaction
    toggleDrawer: ({ drawer }, actions, value) =>
      value !== undefined ? { drawer: value } : { drawer: !drawer },
    setPath: (state, actions, path, pop = false) => {
      if (!pop) {
        if (path === "/") {
          window.history.replaceState(undefined, undefined, path);
        } else {
          window.history.pushState(undefined, undefined, path);
        }
      }
      return { path };
    },
    setError: (state, actions, error) => ({ error }),
    // zoomlevel
    setZoomLevel: (state, actions, zoomLevel) => {
      localStorage.setItem("zoomLevel", zoomLevel.toString());
      return { zoomLevel };
    },
    // Location stuff
    setLocation: (state, actions, newLocation) => {
      const location = cloneAsObject(newLocation);
      localStorage.setItem("location", JSON.stringify(location));
      return { location };
    },
    watchLocation: async (state, actions) => {
      try {
        actions.setLocation(await getLocation());
      } catch (err) {
        if (err.code === 1) {
          return { error: "POI Scout needs location permission to function" };
        } else {
          return { error: "Unexpected error while accessing location" };
        }
      }
      navigator.geolocation.watchPosition(
        location => {
          actions.setLocation(location);
          console.log(location);
        },
        actions.setError,
        { enableHighAccuracy: true }
      );
    },
    // points manipulation
    setPoints: (state, actions, points) => {
      localStorage.setItem("points", JSON.stringify(points));
      return { points };
    },
    addPoi: async ({ points, location }, actions, properties = {}) => {
      actions.setPoints({
        ...points,
        features: [
          ...points.features,
          point(
            [location.coords.longitude, location.coords.latitude],
            properties,
            { id: uuid() }
          )
        ]
      });
    },
    deletePoi: async ({ points }, actions, pointId) => {
      const newPoints = _.cloneDeep(points);
      _.remove(newPoints.features, ({ id }) => id === pointId);
      actions.setPoints({ ...newPoints });
    },
    setPointId: (state, actions, pointId) => ({ pointId }),
    editPoint: async ({ points }, actions, pointId, location) => {
      const newPoints = _.cloneDeep(points);
      _.find(
        newPoints.features,
        ({ id }) => id === pointId
      ).geometry.coordinates = location;
      actions.setPoints(newPoints);
    },
    editPointProperties: async ({ points }, actions, pointId, properties) => {
      const newPoints = _.cloneDeep(points);
      const point = _.find(newPoints.features, ({ id }) => id === pointId);
      point.properties = { ...point.properties, ...properties };
      actions.setPoints(newPoints);
    },
    // types
    setTypes: (state, actions, types) => {
      localStorage.setItem("types", JSON.stringify(types));
      return { types };
    },
    editType: async ({ types }, actions, i, newValues) => {
      const newTypes = _.cloneDeep(types);
      newTypes[i] = { ...types[i], ...newValues };
      actions.setTypes(newTypes);
    },
    deleteType: async ({ types }, actions, i) => {
      const newTypes = _.cloneDeep(types);
      newTypes.splice(i, 1);
      actions.setTypes(newTypes);
    },
    addType: async ({ types }, actions) => {
      const newTypes = _.cloneDeep(types);
      newTypes.push({
        name: `Point of Interest ${types.length + 1}`,
        color: "#000000"
      });
      actions.setTypes(newTypes);
    }
  }
};

export const { Provider, connect, actions } = createStore(config);

window.onpopstate = () => actions.setPath(window.location.pathname, true);
