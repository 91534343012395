import React from "react";
import { actions, connect } from "../store";
import MaterialLeafletMap from "./MaterialLeafletMap";
import PoiMarker from "./PoiMarker";
import ZoomControl from "./ZoomControl";

class MapComponent extends React.Component {
  render() {
    return (
      <MaterialLeafletMap
        location={this.props.location}
        zoom={this.props.zoomLevel}
        maxZoom={20}
        zoomControl={false}
        style={{ height: "100%", width: "100%", marginTop: "-56px" }}
        onZoomEnd={({ target: { _zoom } }) => actions.setZoomLevel(_zoom)}
      >
        <ZoomControl />
        {this.props.points.features
          ? this.props.points.features.map(feature => (
              <PoiMarker
                key={feature.id}
                point={[
                  feature.geometry.coordinates[1],
                  feature.geometry.coordinates[0]
                ]}
                color={feature.properties && feature.properties.color}
                onDragend={location =>
                  actions.editPoint(feature.id, location.reverse())
                }
                onClick={() => actions.setPointId(feature.id)}
              />
            ))
          : null}
      </MaterialLeafletMap>
    );
  }
}

const mapStateToProps = ({ location, points, zoomLevel }) => ({
  points,
  location,
  zoomLevel
});
export default connect(mapStateToProps)(MapComponent);
