import React from "react";
import Markdown from "./Markdown";

const help = `
<img src="icons/192.png" style="width: 50%;margin:0 auto 20px;display:block;max-width:256px"/>

__POI Scout__ is a simple app for recording POI while on the go.

Built by Daniel Schep ([dschep@gmail.com](mailto:dschep@gmail.com), [@schep_](https://twitter.com/schep_))
`;

const Help = () => <Markdown>{help}</Markdown>;

export default Help;
