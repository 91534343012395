import React from "react";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { connect, actions } from "../store";

const whiteOrBlackForeground = backgroundHex => {
  // If RGB --> Convert it to HEX: http://gist.github.com/983661
  const color = parseInt(backgroundHex.slice(1), 16);

  const r = color >> 16;
  const g = (color >> 8) & 255;
  const b = color & 255;

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "black";
  } else {
    return "white";
  }
};

const styles = theme =>
  _.fromPairs(
    new Array(20).fill(1).map((v, i) => [
      `fab${i}`,
      {
        zIndex: 1000,
        position: "fixed",
        bottom: theme.spacing.unit * 2 + 8 + 64 * i,
        right: theme.spacing.unit * 2
      }
    ])
  );

const AddPoiFabs = ({ classes, types }) => (
  <React.Fragment>
    {types.map(({ color, name }, i) => {
      return (
        <Fab
          key={name}
          color="primary"
          className={classes[`fab${i}`]}
          onClick={() => actions.addPoi({ name, color })}
          style={{ backgroundColor: color }}
        >
          <AddIcon style={{ color: whiteOrBlackForeground(color) }} />
        </Fab>
      );
    })}
  </React.Fragment>
);

const mapStateToProps = ({ types }) => ({ types });

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(AddPoiFabs)
);
