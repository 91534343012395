import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
//import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect, actions } from "../store";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12
  }
};

function ButtonAppBar(props) {
  const { classes, path } = props;
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="default"
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <IconButton
            onClick={() =>
              path !== "/" ? window.history.back() : actions.toggleDrawer()
            }
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            {path !== "/" ? <ArrowBackIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {path.slice(1).toUpperCase()}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(({ path }) => ({ path }))(ButtonAppBar)
);
